<mat-accordion multi="true">
  <mat-expansion-panel class="no-shadow" [expanded]="true">
    <mat-expansion-panel-header fxLayout="row" #panelA (click)="panelA._toggle()" [expandedHeight]="expandHeight" [collapsedHeight]="collapseHeight">
      <mat-panel-title>
        <fa-icon [icon]="faHouseUser"></fa-icon>
        <span>Rental unit</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list class="navigation-items">
      <mat-list-item (click)="onSubmitNavItem('addRentalUnit')">
        <a>Add rental unit</a>
      </mat-list-item>
      <mat-list-item>
        <a href="#">Update rental unit</a>
      </mat-list-item>
      <mat-list-item>
        <a href="#">Delete rental unit</a>
      </mat-list-item>
      <mat-list-item>
        <a href="#">Show rental unit</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>


  <mat-expansion-panel class="no-shadow my-0" [expanded]="true">
    <mat-expansion-panel-header fxLayout="row" #panelB (click)="panelB._toggle()" [expandedHeight]="expandHeight" [collapsedHeight]="collapseHeight">
      <mat-panel-title>
        <fa-icon [icon]="faUserCog"></fa-icon>
        <span>Settings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list class="navigation-items">
      <mat-list-item>
        <a href="#">Account</a>
      </mat-list-item>
      <mat-list-item>
        <a href="#">Language</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>

  <mat-expansion-panel class="no-shadow my-0" [expanded]="true">
    <mat-expansion-panel-header fxLayout="row" #panelC (click)="panelC._toggle()" [expandedHeight]="expandHeight" [collapsedHeight]="collapseHeight">
      <mat-panel-title>
        <fa-icon [icon]="faUserCircle"></fa-icon>
        <span>Account</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list class="navigation-items">
      <mat-list-item>
        <a href="#">Account</a>
      </mat-list-item>
      <mat-list-item>
        <a href="#">Language</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-accordion>
