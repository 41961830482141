import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  form2: FormGroup;
  showRegisterForm: boolean;
  showVerificationForm: boolean;
  durationInSeconds = 4;
  registerError = false;
  registerErrormessage: string;


  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private snackBar: MatSnackBar) {
    this.showRegisterForm = true;
    this.showVerificationForm = false;
    this.registerError = false;
  }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      // addressSupplement: ['', Validators.required],
      // street: ['', Validators.required],
      // city: ['', Validators.required],
      // postalCode: ['', Validators.required],
      email: ['', Validators.email],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required]
    });
    this.form2 = this.fb.group({
      verificationCode: ['', Validators.required],
    });

    if (await this.authService.isAuth('/register')) {
      this.router.navigate(['/home']);
    }
  }

  async onSubmitRegister(): Promise<any> {
    console.log('Register user action');
    this.registerError = false;
    if (!this.form.valid) {
      this.registerErrormessage = 'Required entries are empty or incorrect';
      this.registerError = true;
      return false;
    }
    const validatePassword = await this.validatePassword();
    if (validatePassword.status === false) {
      this.registerErrormessage = validatePassword.message;
      this.registerError = true;
      return false;
    }
    const registerResponse = await this.authService.register(this.form.get('email').value, this.form.get('password').value);
    if (registerResponse.status === false && registerResponse.data.name === 'UsernameExistsException') {
      console.log('UsernameExistsException');
      this.registerErrormessage = 'Email already exists';
      this.registerError = true;
      return false;
    }
    if (registerResponse.status === false) {
      this.registerErrormessage = 'registration error please contact info@cloudexcels.com';
      this.registerError = true;
      return false;
    }
    this.showRegisterForm = false;
    this.showVerificationForm = true;
    await this.openSuccessSnackBar();
    return true;
  }

  async onSubmitCancel(): Promise<any> {
    console.log('Close register form');
    await this.router.navigate(['']);
  }

  async onSubmitVerification(): Promise<any> {
    console.log('Verification user action');
    this.registerError = false;

    if (!this.form2.valid) {
      this.registerErrormessage = 'Required entries are empty or incorrect';
      this.registerError = true;
      return false;
    }
    const verificationResponse = await this.authService.userVerification(
      this.form.get('email').value, this.form2.get('verificationCode').value);

    if (verificationResponse.status === false) {
      this.registerErrormessage = 'Wrong verificationCode';
      this.registerError = true;
      return false;
    }
    await this.authService.login(this.form.get('email').value, this.form.get('password').value);
    await this.router.navigate(['home']);

  }

  async openSuccessSnackBar(): Promise<any> {
    this.snackBar.openFromComponent(SuccessRegisterMessageComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['green-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  async openErrorSnackBar(): Promise<any> {
    this.snackBar.openFromComponent(ErrorRegisterMessageComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['red-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  /* Helperfunctions */
  private async validatePassword(): Promise<any> {
    const regex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
    console.log('ps1: ' + this.form.get('password').value);
    console.log('ps2: ' + this.form.get('passwordRepeat').value);
    // tslint:disable-next-line:triple-equals
    if (this.form.get('password').value != this.form.get('passwordRepeat').value) {
      return {status: false, message: 'Passwords not identical'};
    }
    if (this.form.get('password').value.length < 8 || this.form.get('passwordRepeat').value.length < 8) {
      return {status: false, message: 'Password minimum length of 8'};
    }
    if (!regex.test(this.form.get('password').value) && !regex.test(this.form.get('passwordRepeat').value)) {
      return {status: false, message: 'Uppercase lowercase letters and numbers required'};
    }
    return {status: true, message: 'password is valid'};
  }

}


@Component({
  selector: 'app-success-register-message',
  templateUrl: 'snackbarSuccess.html',
  styles: [`
    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `],
})
export class SuccessRegisterMessageComponent {
}


@Component({
  selector: 'app-error-register-message',
  templateUrl: 'snackbarError.html',
  styles: [`
    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `],
})
export class ErrorRegisterMessageComponent {
}

