import { Component, OnInit } from '@angular/core';
import {faUserCog} from '@fortawesome/free-solid-svg-icons';
import {faHouseUser} from '@fortawesome/free-solid-svg-icons';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {HomeComponent} from '../home.component';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.css']
})
export class NavContentComponent implements OnInit {
  expandHeight = '42px';
  collapseHeight = '42px';
  faHouseUser = faHouseUser;
  faUserCog = faUserCog;
  faUserCircle = faUserCircle;

  constructor(private homeComponent: HomeComponent) { }

  ngOnInit(): void {
  }
  async onSubmitNavItem(name): Promise<void> {
    console.log('navigate To: ' + name);
    await this.homeComponent.openContent(name);
  }
}
