import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Auth} from 'aws-amplify';

@Injectable()
export class AuthService {
  authChange = new Subject<boolean>();

  constructor(private router: Router) {
    this.isAuth('').then(res => this.authChange.next(res));
  }

  async login(userEmail, password): Promise<any> {
    return await this.signIn(userEmail, password);
  }

  async isAuth(route): Promise<boolean> {
    console.log('auth Check');
    return await this.checkSession().then(data => {
      if (data.status === true) {
        console.log('is Auth');
        this.authChange.next(true);
        return true;
      } else {
        console.log('is not Auth');
        this.authChange.next(false);
        if (route === '/') {
          this.router.navigate(['']);
          return false;
        }
        return false;
      }
    });
  }

  async changeUserPassword(oldPassword, newPassword, emailUser, status): Promise<any> {
    return await this.changePassword(oldPassword, newPassword, emailUser, status);
  }

  async logout(): Promise<any> {
    await this.signOut();
    this.authChange.next(false);
    this.router.navigate(['']);
  }

  async register(userEmail, userPassword): Promise<any> {
    return await this.signUp(userEmail, userPassword);
  }

  async userVerification(userEmail, verificationCode): Promise<any> {
    return await this.confirmSignUp(userEmail, verificationCode);
  }


  /*
   *
   Private Functions
   *
   */

  private async signUp(userEmail, userPassword): Promise<any> {
    try {
      const {user} = await Auth.signUp({
        attributes: undefined,
        clientMetadata: {},
        password: userPassword,
        username: userEmail,
        validationData: [],
      });
      console.log('SignUpResponse:' + JSON.stringify(user));
      return {status: true, data: user};
    } catch (error) {
      console.log('error signing up:', error);
      return {status: false, data: error};
    }
  }

  private async confirmSignUp(userEmail, verificationCode): Promise<any> {
    try {
      const res = await Auth.confirmSignUp(userEmail, verificationCode);
      return {status: true, data: res};
    } catch (error) {
      return {status: false, data: error};
    }
  }

  private async signIn(username, password): Promise<any> {
    console.log('sigIn');
    const loginProcess = await Auth.signIn(username, password).then(data => {
      if (typeof data.Session !== 'undefined') {
        this.authChange.next(true);
        return {status: true, message: data.challengeName, loginData: data};
      } else {
        return {status: false, message: data.message};
      }
    }).catch((error: any) => {
      return {status: false, message: 'Login Error', loginData: error};
    });
    console.log('Login Status:' + loginProcess.status + ', LoginMessage: ' + loginProcess.message);
    return loginProcess;
  }

  private async checkSession(): Promise<any> {
    console.log('check Session');
    try {
      const session = await Auth.currentSession();
      // const idToken = await session.getIdToken();
      // const jwt = await idToken.getJwtToken();
      console.log('Session Token: ' + JSON.stringify(session));
      return {status: true, message: session};
    } catch (error) {
      console.log('Session error: ', error);
      return {status: false, message: error};
    }
  }

  private async changePassword(oldPassword, newPassword, emailUser, status): Promise<any> {
    const user = await Auth.signIn(emailUser, oldPassword).then(data => {
      return data;
    }).catch((error: any) => {
      return error;
    });
    if (status === 'NEW_PASSWORD_REQUIRED') {
      try {
        const pwdChange = await Auth.completeNewPassword(user, newPassword, {email: emailUser});
        return {status: true, message: pwdChange};
      } catch (error) {
        return {status: false, message: error};
      }
    } else if (status === 'CHANGE_CURRENT_PASSWORD') {
      try {
        const pwdChange = await Auth.changePassword(user, oldPassword, newPassword);
        return {status: true, message: pwdChange};
      } catch (error) {
        return {status: false, message: error};
      }
    } else {
      return {status: false, message: 'Missing password status'};
    }
  }

  private async signOut(): Promise<any> {
    try {
      const signOut = await Auth.signOut();
      return {status: true, message: signOut};
    } catch (error) {
      return {status: false, message: error};
    }
  }


}
