import {Injectable} from '@angular/core';
import {API} from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  constructor() {
  }

  async addRentalUnit(rentalUnitBody): Promise<boolean> {
    console.log('addRentalUnit Service');

    console.log(rentalUnitBody);
    const response = await this.getData();
    console.log('api response: ' + JSON.stringify(response));
    return true;
  }


  async getData(): Promise<any> {
    const apiName = 'SmartHomeApiGateway';
    const path = '/rentalUnitService/customer/123456/houseUnit';
    const myInit = { // OPTIONAL
      headers: {
        Accept: '*/*'
      }, // OPTIONAL
    };
    return API.get(apiName, path, myInit);
  }

}
