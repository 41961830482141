import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  facebook = faFacebookF;
  google = faGoogle;
  twitter = faTwitter;
  form: FormGroup;
  form2: FormGroup;
  userEmailToLogin: string;
  passwordToLogin: string;
  loginError: boolean;
  loginErrormessage: string;
  showLoginForm: boolean;
  setNewPasswordError: boolean;
  setNewPasswordErrormessage: string;
  showSetNewPasswordForm: boolean;
  passwordChangeStatusMessage: string;


  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) {
    this.loginError = false;
    this.showLoginForm = true;
    this.setNewPasswordError = false;
    this.showSetNewPasswordForm = false;
  }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
    this.form2 = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required],
    });
    this.loginError = false;
    this.showLoginForm = true;
    this.setNewPasswordError = false;
    this.showSetNewPasswordForm = false;

    if (await this.authService.isAuth('/')) {
      await this.router.navigate(['/home']);
    }

  }

  async onSubmitLogin(): Promise<any> {
    console.log('login onSubmit');
    if (!this.form.valid) {
      this.loginErrormessage = 'Required entries are empty or incorrect';
      this.loginError = true;
      return false;
    }
    this.userEmailToLogin = this.form.get('email').value;
    this.passwordToLogin = this.form.get('password').value;
    const loginUserData = await this.authService.login(this.userEmailToLogin, this.passwordToLogin);
    if (loginUserData.status === false) {
      this.loginErrormessage = 'Username or password wrong';
      this.loginError = true;
      return false;
    }
    console.log('login Status: ' + loginUserData.status);
    if (loginUserData.status === true && loginUserData.message === 'NEW_PASSWORD_REQUIRED') {
      console.log('Set New Password');
      this.showLoginForm = false;
      this.showSetNewPasswordForm = true;
      return false;
    }
    if (loginUserData.status === true && loginUserData.message !== 'NEW_PASSWORD_REQUIRED') {
      await this.router.navigate(['home']);
      return true;
    }
  }


  async onSubmitChangePassword(): Promise<any> {
    const validatePassword = await this.validatePassword();
    if (validatePassword.status === false) {
      this.setNewPasswordError = true;
      this.setNewPasswordErrormessage = validatePassword.message;
      return false;
    }
    const resChangePassword = await this.authService.changeUserPassword(this.passwordToLogin, this.form2.get('password').value,
      this.userEmailToLogin, 'NEW_PASSWORD_REQUIRED');
    const resIsAuth = await this.authService.isAuth('/');
    if (!resChangePassword.status || !resIsAuth) {
      await this.router.navigate(['']);
      return false;
    }
    if (resChangePassword.status && resIsAuth) {
      await this.router.navigate(['/home']);
      return true;
    }
    return false;
  }


  /* Helperfunctions */
  private async validatePassword(): Promise<any> {
    const regexLogin = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
    // tslint:disable-next-line:triple-equals
    if (this.form.get('password').value != this.form.get('passwordRepeat').value) {
      return {status: false, message: 'Passwords not identical'};
    }
    if (this.form.get('password').value.length < 8 || this.form.get('passwordRepeat').value.length < 8) {
      return {status: false, message: 'Password minimum length of 8'};
    }
    if (!regexLogin.test(this.form.get('password').value) && !regexLogin.test(this.form.get('passwordRepeat').value)) {
      return {status: false, message: 'Uppercase lowercase letters and numbers required'};
    }
    return {status: true, message: 'password is valid'};
  }


}
