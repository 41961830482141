<mat-card>
  <mat-card-content>
    <h2 *ngIf="showRegisterForm" >Register</h2>
    <h4 *ngIf="showRegisterForm" >A general registration form designt A general </h4>
    <form *ngIf="showRegisterForm" [formGroup]="form" >
      <mat-error *ngIf="registerError">
        {{registerErrormessage}}
      </mat-error>
      <!--<div class="container1">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="First Name" formControlName="firstName" required>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Last Name" formControlName="lastName" required>
        </mat-form-field>
      </div>
      <div class="container2">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Street" formControlName="street" required>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Address Supplement" formControlName="addressSupplement" required>
        </mat-form-field>
      </div>
      <div class="container3">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="City" formControlName="city" required>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Postal Code" formControlName="postalCode" required>
        </mat-form-field>
      </div>-->
      <div class="container4">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Email" formControlName="email" name="email" required>
        </mat-form-field>
      </div>
      <div class="container5">
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Password" formControlName="password" name="password" required>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Repeat password" formControlName="passwordRepeat" name="passwordRepeat" required>
        </mat-form-field>
      </div>
      <div class="container7">
        <button name="btn1" mat-raised-button class="btn1" (click)="onSubmitRegister()">Submit</button>
        <button name="btn2" mat-raised-button class="btn2" (click)="onSubmitCancel()">Cancel</button>
      </div>
    </form>

    <h2 *ngIf="showVerificationForm" >Verification</h2>
    <h4 *ngIf="showVerificationForm" >Insert your Verfication Code</h4>
    <form *ngIf="showVerificationForm" [formGroup]="form2" >
      <mat-error *ngIf="registerError">
        {{registerErrormessage}}
      </mat-error>
      <div class="container4">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Verification Code" formControlName="verificationCode" required>
        </mat-form-field>
      </div>
      <div class="container7">
        <button mat-raised-button class="btn1" (click)="onSubmitVerification()">Submit</button>
      </div>
    </form>

  </mat-card-content>
</mat-card>
