import {Component, OnInit} from '@angular/core';
import {HomeComponent} from '../home/home.component';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  constructor(private homeComponent: HomeComponent) {
  }

  ngOnInit(): void {
  }

  async onSubmitAdd(): Promise<void> {
    console.log('open /add');
    await this.homeComponent.openContent('addRentalUnit');
  }

}
