<mat-card>
  <mat-card-content>
    <h2 *ngIf="showLoginForm" >Lohr Smart Home</h2>
    <h2 *ngIf="showSetNewPasswordForm" >Set new Password</h2>

    <form *ngIf="showLoginForm" [formGroup]="form" (ngSubmit)="onSubmitLogin()">
      <mat-error *ngIf="loginError">
        {{loginErrormessage}}
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="email" required name="email">
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" name="password" required>
      </mat-form-field>

      <div>
        <div class="container1">Forgot Password?</div>
        <div><button mat-raised-button class="button-login" type="submit">Sign In</button></div>
      </div>
    </form>

    <form *ngIf="showSetNewPasswordForm" [formGroup]="form2" (ngSubmit)="onSubmitChangePassword()">
      <mat-error *ngIf="setNewPasswordError">
        {{setNewPasswordErrormessage}}
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Repeat password" formControlName="passwordRepeat" required>
      </mat-form-field>
      <button mat-raised-button class="button-login">Change Password</button>
    </form>

    <div class="container2" *ngIf="showLoginForm"><a>or Sign in with:</a></div>
    <div class="container3" *ngIf="showLoginForm">
      <a class="btn1" mat-raised-button href="https://www.google.com/" target="_blank"><fa-icon [icon]="facebook"></fa-icon></a>
      <a class="btn2" mat-raised-button href="https://www.google.com/" target="_blank"><fa-icon [icon]="google"></fa-icon></a>
      <a class="btn3" mat-raised-button href="https://www.google.com/" target="_blank"><fa-icon [icon]="twitter"></fa-icon></a>
    </div>

    <div class="container4" *ngIf="showLoginForm">
      <p>Not a member? <a routerLink="/register">Sign Up</a></p>
    </div>

  </mat-card-content>
</mat-card>



<!--<form *ngIf="setNewUserPassword" [formGroup]="form2" (ngSubmit)="onSubmitChangePassword()">
  <h2>Set new Password</h2>
  <mat-error *ngIf="notAuthException">
    {{passwordChangeStatusMessage}}
  </mat-error>
  <mat-form-field class="full-width-input">
    <input matInput type="password" placeholder="Passwort" formControlName="password" required>
  </mat-form-field>
  <mat-form-field class="full-width-input">
    <input matInput type="password" placeholder="Passwort wiederholen" formControlName="passwordRepeat" required>
  </mat-form-field>
  <button mat-raised-button class="button-login">Ändern</button>
</form>-->
