import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';
import {faHome} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faSignOut = faPowerOff;
  faHome = faHome;
  dashboardSite: boolean;
  addRentalUnit: boolean;

  constructor(private authService: AuthService) {
    this.dashboardSite = true;
    this.addRentalUnit = false;
  }

  ngOnInit(): void {
  }

  onSubmitLogout(): void {
    console.log('logout');
    this.authService.logout();
  }

  async openContent(name): Promise<void> {
    if (name === 'dashboard') {
      this.addRentalUnit = false;
      this.dashboardSite = true;
    }
    if (name === 'addRentalUnit') {
      this.dashboardSite = false;
      this.addRentalUnit = true;
    }
  }

}
