import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {AuthService} from './services/auth.service';
import {RentalService} from './services/rentalservice.service';

import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SuccessRegisterMessageComponent} from './register/register.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorRegisterMessageComponent} from './register/register.component';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FlexLayoutModule} from '@angular/flex-layout';

/* Add Amplify imports */
import Amplify from 'aws-amplify';
import {HomeComponent} from './home/home.component';
import {RegisterComponent} from './register/register.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AddRentalUnitComponent} from './add-rental-unit/add-rental-unit.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {NavContentComponent} from './home/nav-content/nav-content.component';
import {SuccessAddRentalunitMessageComponent} from './add-rental-unit/add-rental-unit.component';

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_nHNp9hZZl',
    userPoolWebClientId: '7i1k7e12nfieusthl1ae2pv06c',
  },
  API: {
    endpoints: [
      {
        name: 'SmartHomeApiGateway',
        endpoint: 'https://q4hd56oiyb.execute-api.eu-west-1.amazonaws.com/prod'
      }
    ]
  }
});


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    RegisterComponent,
    SuccessRegisterMessageComponent,
    ErrorRegisterMessageComponent,
    AddRentalUnitComponent,
    DashboardComponent,
    NavContentComponent,
    SuccessAddRentalunitMessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatCheckboxModule,
    FormsModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatExpansionModule
  ],
  providers: [AuthService, RentalService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
