<!-- on small Screens -->
<div class="withoutMatCards" fxHide.md fxHide.lg fxHide.xl>
  <div class="container1" fxHide.md fxHide.lg fxHide.xl>
    <h2>Herzlich Willkommen</h2>
    <h2>Bla text text text</h2>
    <p>Unverständliches Übersetzungs-Kauderwelsch kann fremdsprachigen Geschäftspartnern leicht die
      Sprache verschlagen und Ihr Unternehmen teuer zu stehen kommen.</p>
  </div>
  <div class="container2" fxHide.md fxHide.lg fxHide.xl>
    <button mat-raised-button name="addRentalUnitButtonSmallScreen" class="button-addRentalUser" type="submit" (click)="onSubmitAdd()">Add rental unit
    </button>
  </div>

  <div class="container1" fxHide.md fxHide.lg fxHide.xl>
    <h2>Infos</h2>
    <h2>Bla text text</h2>
    <p>Unverständliches Übersetzungs-Kauderwelsch kann fremdsprachigen Geschäftspartnern leicht die
      Sprache verschlagen und Ihr Unternehmen teuer zu stehen kommen.</p>
  </div>
</div>

<!-- on large Screens -->

<div class="withMatCards" fxHide.xs fxHide.sm>
  <mat-card>
    <mat-card-content>
      <div class="container1">
        <h2>Herzlich Willkommen</h2>
        <h2>Bla text text text</h2>
        <p>Unverständliches Übersetzungs-Kauderwelsch kann fremdsprachigen Geschäftspartnern leicht die
          Sprache verschlagen und Ihr Unternehmen teuer zu stehen kommen.</p>
      </div>
      <div class="container2">
        <button mat-raised-button name="addRentalUnitButtonLargeScreen" class="button-addRentalUser" type="submit" (click)="onSubmitAdd()">Add rental unit
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div class="container1">
        <h2>Infos</h2>
        <h2>Bla text text</h2>
        <p>Unverständliches Übersetzungs-Kauderwelsch kann fremdsprachigen Geschäftspartnern leicht die
          Sprache verschlagen und Ihr Unternehmen teuer zu stehen kommen.</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
