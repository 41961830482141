import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HomeComponent} from '../home/home.component';
import {faSlidersH} from '@fortawesome/free-solid-svg-icons';
import {SuccessRegisterMessageComponent} from '../register/register.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RentalService} from '../services/rentalservice.service';

@Component({
  selector: 'app-add-rental-unit',
  templateUrl: './add-rental-unit.component.html',
  styleUrls: ['./add-rental-unit.component.css']
})
export class AddRentalUnitComponent implements OnInit {
  rentalUnitNumbersRadioButtons = true;
  sanckBarDurationInSeconds = 2;
  faListUl = faSlidersH;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;

  // rental unit size slider
  rentalUnitSizeSliderInvert = false;
  rentalUnitSizeSliderMax = 200;
  rentalUnitSizeSliderMin = 10;
  rentalUnitSizeSliderStep = 1;
  rentalUnitSizeSliderThumbLabel = true;
  rentalUnitSizeSliderVertical = false;

  // rooms number slider
  roomsSliderInvert = false;
  roomsSliderMax = 10;
  roomsSliderMin = 1;
  roomsSliderStep = 1;
  roomsSliderThumbLabel = true;
  roomsSliderVertical = false;

  firstFormGroupError: boolean;
  firstFormGroupErrorMessage = '';
  selectedFirstFormGroupCheckBoxes = '';
  selectedFirstFormGroupRadioButtons = '';

  secondFormGroupErrorMessage = '';
  secondFormGroupError: boolean;
  selectedSecondFormGroupCheckBoxes = '';

  thirdFormGroupErrorMessage = '';
  thirdFormGroupError: boolean;
  selectedThirdFormGroupCheckBoxes = '';

  fourthFormGroupErrorMessage = '';
  fourthFormGroupError: boolean;
  selectedFourthFormGroupCheckBoxes = '';

  fifthFormGroupErrorMessage = '';
  fifthFormGroupError: boolean;
  selectedFifthFormGroupCheckBoxes = '';

  sixthFormGroupErrorMessage = '';
  sixthFormGroupError: boolean;
  selectedSixthFormGroupCheckBoxes: boolean;

  SolarPowerSlideCheckedStatus: string;


  constructor(private fb: FormBuilder, private homeComponent: HomeComponent, private snackBar: MatSnackBar,
              private rentalService: RentalService) {
    this.secondFormGroupError = false;
  }

  ngOnInit(): void {
    this.firstFormGroup = this.fb.group({
      rentalUnitName: ['', Validators.required],
      rentalUnitFloor: ['', Validators.required],
      rentalUnitSize: ['', Validators.required],
      numberOfRooms: ['', ''],
    });
    this.secondFormGroup = this.fb.group({
      oilHeating: ['', ''],
      electricHeating: ['', ''],
      gasHeating: ['', ''],
      othersHeating: ['', ''],
      solarHeating: ['', ''],
      heating: ['', Validators.required],
    });
    this.thirdFormGroup = this.fb.group({
      digitalElectricity: ['', ''],
      analogElectricity: ['', ''],
      electricity: ['', Validators.required],
      solarPower: ['', Validators.required],
    });
    this.fourthFormGroup = this.fb.group({
      digitalWaterMeter: ['', ''],
      analogWaterMeter: ['', ''],
      waterMeter: ['', Validators.required],
    });

    this.fifthFormGroup = this.fb.group({
      underfloorHeating: ['', ''],
      radiators: ['', ''],
      underfloorHeatingAndRadiators: ['', ''],
      heatingSystem: ['', Validators.required],
    });

    this.sixthFormGroup = this.fb.group({
      inputCheckedAndAccept: ['', Validators.required],
    });

    this.thirdFormGroup.patchValue({
      solarPower: false,
    });
    this.SolarPowerSlideCheckedStatus = 'No';

    this.firstFormGroup.patchValue({
      numberOfRooms: '1',
    });

    this.firstFormGroup.patchValue({
      rentalUnitSize: '10',
    });

    this.sixthFormGroup.patchValue({
      inputCheckedAndAccept: false,
    });
  }

  async onSubmitCancel(): Promise<void> {
    console.log('open /dashboard');
    await this.homeComponent.openContent('dashboard');
  }

  async clearFields(section): Promise<void> {
    if (section === 'firstSection') {
      this.firstFormGroup.reset();
      this.firstFormGroupError = false;
    }
    if (section === 'secondSection') {
      this.secondFormGroup.reset();
      this.secondFormGroupError = false;
    }
    if (section === 'thirdSection') {
      this.thirdFormGroup.reset();
      this.thirdFormGroupError = false;
      this.SolarPowerSlideCheckedStatus = 'No';
    }
    if (section === 'fourthSection') {
      this.fourthFormGroup.reset();
      this.fourthFormGroupError = false;
    }
    if (section === 'fifthSection') {
      this.fifthFormGroup.reset();
      this.fifthFormGroupError = false;
    }
  }

  async radioButtonGroupFirstFormValidator(checkboxName): Promise<boolean> {
    console.log('radioButtonGroupFirstFormValidator function');
    if (checkboxName === 'one') {
      await this.firstFormGroup.patchValue({
        numberOfRooms: '1'
      });
    } else if (checkboxName === 'two') {
      await this.firstFormGroup.patchValue({
        numberOfRooms: '2'
      });
    } else if (checkboxName === 'three') {
      await this.firstFormGroup.patchValue({
        numberOfRooms: '3'
      });
    } else if (checkboxName === 'four') {
      await this.firstFormGroup.patchValue({
        numberOfRooms: '4'
      });
    } else if (checkboxName === 'five') {
      await this.firstFormGroup.patchValue({
        numberOfRooms: '5'
      });
    }
    return true;
  }

  async checkboxGroupSecondFormValidator(checkboxName): Promise<boolean> {
    console.log('checkboxFormSecondValidator function');
    if (checkboxName === this.selectedSecondFormGroupCheckBoxes) {
      this.selectedSecondFormGroupCheckBoxes = '';
      await this.secondFormGroup.patchValue({
        heating: this.selectedSecondFormGroupCheckBoxes
      });
      return false;
    }
    this.selectedSecondFormGroupCheckBoxes = checkboxName;
    if (checkboxName === 'oilHeating') {
      await this.secondFormGroup.patchValue({
        oilHeating: this.selectedSecondFormGroupCheckBoxes,
        heating: this.selectedSecondFormGroupCheckBoxes
      });
    } else if (checkboxName === 'electricHeating') {
      await this.secondFormGroup.patchValue({
        electricHeating: this.selectedSecondFormGroupCheckBoxes,
        heating: this.selectedSecondFormGroupCheckBoxes
      });
    } else if (checkboxName === 'gasHeating') {
      await this.secondFormGroup.patchValue({
        gasHeating: this.selectedSecondFormGroupCheckBoxes,
        heating: this.selectedSecondFormGroupCheckBoxes
      });
    } else if (checkboxName === 'solarHeating') {
      await this.secondFormGroup.patchValue({
        solarHeating: this.selectedSecondFormGroupCheckBoxes,
        heating: this.selectedSecondFormGroupCheckBoxes
      });
    } else if (checkboxName === 'othersHeating') {
      await this.secondFormGroup.patchValue({
        othersHeating: this.selectedSecondFormGroupCheckBoxes,
        heating: this.selectedSecondFormGroupCheckBoxes
      });
    }
    return true;
  }

  async checkboxGroupThirdFormValidator(checkboxName): Promise<boolean> {
    console.log('checkboxThirdFormValidator function');
    if (checkboxName === this.selectedThirdFormGroupCheckBoxes) {
      this.selectedThirdFormGroupCheckBoxes = '';
      await this.thirdFormGroup.patchValue({
        electricity: this.selectedThirdFormGroupCheckBoxes
      });
      return false;
    }
    this.selectedThirdFormGroupCheckBoxes = checkboxName;
    if (checkboxName === 'digitalElectricity') {
      await this.thirdFormGroup.patchValue({
        digitalElectricity: this.selectedThirdFormGroupCheckBoxes,
        electricity: this.selectedThirdFormGroupCheckBoxes
      });
    } else if (checkboxName === 'analogElectricity') {
      await this.thirdFormGroup.patchValue({
        analogElectricity: this.selectedThirdFormGroupCheckBoxes,
        electricity: this.selectedThirdFormGroupCheckBoxes
      });
    }
    return true;
  }

  async checkboxGroupFourthFormValidator(checkboxName): Promise<boolean> {
    console.log('checkboxFourthFormValidator function');
    if (checkboxName === this.selectedFourthFormGroupCheckBoxes) {
      this.selectedFourthFormGroupCheckBoxes = '';
      await this.fourthFormGroup.patchValue({
        waterMeter: this.selectedFourthFormGroupCheckBoxes
      });
      return false;
    }
    this.selectedFourthFormGroupCheckBoxes = checkboxName;
    if (checkboxName === 'digitalWaterMeter') {
      await this.fourthFormGroup.patchValue({
        digitalWaterMeter: this.selectedFourthFormGroupCheckBoxes,
        waterMeter: this.selectedFourthFormGroupCheckBoxes
      });
    } else if (checkboxName === 'analogWaterMeter') {
      await this.fourthFormGroup.patchValue({
        analogWaterMeter: this.selectedFourthFormGroupCheckBoxes,
        waterMeter: this.selectedFourthFormGroupCheckBoxes
      });
    }
    return true;
  }

  async checkboxGroupFifthFormValidator(checkboxName): Promise<boolean> {
    console.log('checkboxFifthFormValidator function');
    if (checkboxName === this.selectedFifthFormGroupCheckBoxes) {
      this.selectedFifthFormGroupCheckBoxes = '';
      await this.fifthFormGroup.patchValue({
        heatingSystem: this.selectedFifthFormGroupCheckBoxes
      });
      return false;
    }
    this.selectedFifthFormGroupCheckBoxes = checkboxName;
    if (checkboxName === 'underfloorHeating') {
      await this.fifthFormGroup.patchValue({
        underfloorHeating: this.selectedFifthFormGroupCheckBoxes,
        heatingSystem: this.selectedFifthFormGroupCheckBoxes
      });
    } else if (checkboxName === 'radiators') {
      await this.fifthFormGroup.patchValue({
        radiators: this.selectedFifthFormGroupCheckBoxes,
        heatingSystem: this.selectedFifthFormGroupCheckBoxes
      });
    } else if (checkboxName === 'underfloorHeatingAndRadiators') {
      await this.fifthFormGroup.patchValue({
        underfloorHeatingAndRadiators: this.selectedFifthFormGroupCheckBoxes,
        heatingSystem: this.selectedFifthFormGroupCheckBoxes
      });
    }
    return true;
  }

  async checkboxGroupSixthFormValidator(checkboxName): Promise<boolean> {
    console.log('checkboxSixthFormValidator function');
    if (checkboxName === this.selectedSixthFormGroupCheckBoxes) {
      console.log('1');
      this.selectedSixthFormGroupCheckBoxes = false;
      await this.sixthFormGroup.patchValue({
        inputCheckedAndAccept: this.selectedSixthFormGroupCheckBoxes
      });
      return false;
    }
    this.selectedSixthFormGroupCheckBoxes = checkboxName;
    if (checkboxName === true) {
      console.log('2');
      await this.sixthFormGroup.patchValue({
        inputCheckedAndAccept: this.selectedSixthFormGroupCheckBoxes
      });
    }
    return true;
  }

  async onSubmitFirstFormGroup(): Promise<boolean> {
    console.log('onSubmitFirstFormGroup');
    this.firstFormGroupError = false;
    if (!this.firstFormGroup.valid) {
      this.firstFormGroupErrorMessage = `Required entries are empty`;
      this.firstFormGroupError = true;
      return false;
    }
    return true;
  }


  async onSubmitSecondFormGroup(): Promise<boolean> {
    console.log('onSubmitSecondFormGroup');
    this.secondFormGroupError = false;
    if (!this.secondFormGroup.valid) {
      this.secondFormGroupErrorMessage = `Required entries are empty`;
      this.secondFormGroupError = true;
      return false;
    }
    return true;
  }

  async onSubmitThirdFormGroup(): Promise<boolean> {
    console.log('onSubmitThirdFormGroup');
    this.thirdFormGroupError = false;
    if (!this.thirdFormGroup.valid) {
      this.thirdFormGroupErrorMessage = `Required entries are empty`;
      this.thirdFormGroupError = true;
      return false;
    }
    return true;
  }

  async onSubmitFourthFormGroup(): Promise<boolean> {
    console.log('onSubmitFourthFormGroup');
    this.fourthFormGroupError = false;
    if (!this.fourthFormGroup.valid) {
      this.fourthFormGroupErrorMessage = `Required entries are empty`;
      this.fourthFormGroupError = true;
      return false;
    }
    return true;
  }

  async onSubmitFifthFormGroup(): Promise<boolean> {
    console.log('onSubmitFifthFormGroup');
    this.fifthFormGroupError = false;
    if (!this.fifthFormGroup.valid) {
      this.fifthFormGroupErrorMessage = `Required entries are empty`;
      this.fifthFormGroupError = true;
      return false;
    }
    return true;
  }

  async onClickSolarSlideToggle(): Promise<boolean> {
    console.log('onClickSolarSlideToggle');
    if (this.thirdFormGroup.get('solarPower').value === false) {
      this.SolarPowerSlideCheckedStatus = 'Yes';
    } else {
      this.SolarPowerSlideCheckedStatus = 'No';
    }
    return true;
  }

  async openSuccessSnackBar(): Promise<any> {
    this.snackBar.openFromComponent(SuccessRegisterMessageComponent, {
      duration: this.sanckBarDurationInSeconds * 1000,
      panelClass: ['green-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  async changeFormGroupRadioButton(): Promise<any> {
    console.log('changeFormGroupRadioButton function');
    if (this.rentalUnitNumbersRadioButtons === true) {
      this.rentalUnitNumbersRadioButtons = false;
    } else {
      this.rentalUnitNumbersRadioButtons = true;
      await this.firstFormGroup.patchValue({
        numberOfRooms: '1'
      });
    }
  }


  async onSubmitAddRentalUnit(): Promise<boolean> {
    if (this.sixthFormGroup.get('inputCheckedAndAccept').value === false) {
      this.sixthFormGroupErrorMessage = 'please accept input and AGB';
      this.sixthFormGroupError = true;
      return false;
    }
    if (!this.firstFormGroup.valid &&
      !this.secondFormGroup.valid &&
      !this.thirdFormGroup.valid &&
      !this.fourthFormGroup.valid &&
      !this.fifthFormGroup.valid) {
      this.sixthFormGroupErrorMessage = 'Error please check your entries';
      this.sixthFormGroupError = true;
      return false;
    }
    await this.homeComponent.openContent('dashboard');
    await this.openSuccessSnackBar();
    const rentalUnitBody = {
      name: this.firstFormGroup.get('rentalUnitName').value,
      floor: this.firstFormGroup.get('rentalUnitFloor').value,
      unitSize: this.firstFormGroup.get('rentalUnitSize').value,
      numberRooms: this.firstFormGroup.get('numberOfRooms').value,
      heating: this.secondFormGroup.get('heating').value,
      electricityMeter: this.thirdFormGroup.get('electricity').value,
      solarPower: this.thirdFormGroup.get('solarPower').value,
      waterMeter: this.fourthFormGroup.get('waterMeter').value,
      heatingSystem: this.fifthFormGroup.get('heatingSystem').value
    };
    await this.rentalService.addRentalUnit(rentalUnitBody);
    return true;
  }
}

@Component({
  selector: 'app-success-add-rentalunit-message-component',
  templateUrl: 'snackbarSuccess.html',
  styles: [`
    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `],
})
export class SuccessAddRentalunitMessageComponent {
}

